<template>
  <div>
    <iframe :src="s3" frameBorder="0"/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectid: '',
      s3: '',
    }
  },
  mounted(){
    this.projectid= this.$route.params.projectid;
    switch (this.projectid) {
      case "alix":
        this.s3 = "https://alix360.s3.ap-southeast-1.amazonaws.com/Alix_211118/Alix+Pano.html";
        break;
      case "aurel":
        this.s3 = "https://aurelsanctuary.s3.ap-southeast-1.amazonaws.com/aurel+pano.html";
        break;
      case "aurelian":
        this.s3 = "https://tour-jp.metareal.com/apps/player?asset=97468c3d-59ae-45ad-95d9-cb9b3cf79805";
        break;
      case 'aruna':
        this.s3 = "https://m-aruna.s3.ap-southeast-1.amazonaws.com/Dephy+Final/M+Aruna+Delphy+Pano.html";
        break;

      default:
        break;
    }

    const title =  this.projectid.charAt(0).toUpperCase() + this.projectid.slice(1); 
    document.title = `${title} - Studio Multiply`;
  }
}
</script>

<style scoped>
div{
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
iframe{
  width: 100%;
  height: 100%;
}
</style>;